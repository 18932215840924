<template>
    <div class="dashboard-view-router">
        <h1 class="dashboard-view-router__title">
            <img v-if="icon" :src="icon" alt="icon" />
            <div v-else-if="fasIcon"><i :class="fasIcon"></i></div>
            {{ title }}
        </h1>
        <button v-if="goback" class="dashboard-view-router__btn" @click="goBack">
            <div><i class="fal fa-chevron-circle-left" /></div>
            {{ $lang.components.cardDashboardRouter.back }}
        </button>
    </div>
</template>

<script lang="ts">
export default defineComponent({
    props: {
        title: {
            type: String,
        },
        icon: {
            type: String,
            default: null,
        },
        fasIcon: {
            type: String,
            default: null,
        },
        goback: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        goBack(): void {
            this.$router.push(
                `/${this.$lang.routes.dashboard}${
                    this.$route.params.user ? '/' + this.$route.params.user : ''
                }`,
            )
        },
    },
})
</script>

<style lang="postcss" scoped>
.dashboard-view-router {
    @apply flex justify-between border-b border-opacity-40 px-2 pb-2;
    &__title {
        @apply flex items-center gap-1.5 text-base font-semibold text-[#1B1B1B] lg:text-lg;
        img {
            @apply h-4 w-4;
        }
    }
    &__btn {
        @apply flex items-center gap-1 text-[#94949B];
    }
}
</style>
